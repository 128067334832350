var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataLoaded)?_c('div',{staticClass:"tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse tw-p-8"},[_c('div',[_c('div',[_c('img',{staticClass:"tw-mx-auto tw-mb-4",attrs:{"src":_vm.invitationExists ? _vm.surveyIcon : _vm.envelopeIcon,"alt":"Airplane"}}),_c('h2',{staticClass:"tw-text-lg tw-font-semibold tw-text-dark-green tw-w-4/6 tw-mx-auto"},[_vm._v(" "+_vm._s(_vm.confirmationTitle)+" ")]),_c('p'),_c('p',{staticClass:"tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22 tw-w-5/6 tw-mx-auto"},[_vm._v(" "+_vm._s(_vm.confirmationParagraph)+" ")]),_c('v-btn',{staticClass:"tw-mt-40 tw-mb-6 tw-tracking-normal",attrs:{"rounded":"","block":"","color":"secondary","x-large":""},on:{"click":_vm.goToRoute}},[_vm._v(_vm._s(_vm.invitationExists ? "Let's go!" : "Create Invitation"))]),_c('router-link',{staticClass:"tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block",attrs:{"to":{
          name: 'SurveyResults',
          params: {
            id: _vm.trip.id,
            surveyId: _vm.trip.surveys[_vm.trip.surveys.length - 1].id
          }
        }}},[_vm._v(" Skip and Create Later ")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }