<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse tw-p-8"
    v-if="dataLoaded"
  >
    <div>
      <div>
        <img
          :src="invitationExists ? surveyIcon : envelopeIcon"
          alt="Airplane"
          class="tw-mx-auto tw-mb-4"
        />
        <h2
          class="tw-text-lg tw-font-semibold tw-text-dark-green tw-w-4/6 tw-mx-auto"
        >
          {{ confirmationTitle }}
        </h2>
        <p></p>
        <p
          class="tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22 tw-w-5/6 tw-mx-auto"
        >
          {{ confirmationParagraph }}
        </p>
        <v-btn
          rounded
          block
          color="secondary"
          x-large
          class="tw-mt-40 tw-mb-6 tw-tracking-normal"
          @click="goToRoute"
          >{{ invitationExists ? "Let's go!" : "Create Invitation" }}</v-btn
        >
        <router-link
          class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
          :to="{
            name: 'SurveyResults',
            params: {
              id: trip.id,
              surveyId: trip.surveys[trip.surveys.length - 1].id
            }
          }"
        >
          Skip and Create Later
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import envelopeIcon from "@/assets/svgs/surveys/envelope.svg";
import surveyIcon from "@/assets/svgs/create-a-trip/survey-icon.svg";

export default {
  data() {
    return {
      dataLoaded: false,
      invitationExists: null,
      envelopeIcon,
      surveyIcon
    };
  },
  props: {},
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    confirmationTitle() {
      return this.trip && this.trip.trip_invitation
        ? "You did it! Survey was created."
        : "You did it! Now let's invite the crew.";
    },
    confirmationParagraph() {
      return this.trip && this.trip.trip_invitation
        ? "Now you can take your survey and see live results roll in, as well as share the survey with your crew."
        : "Your survey will be attached to your trip invitation. Click create invitation to get started.";
    }
  },
  methods: {
    goToRoute() {
      if (sessionStorage.getItem("fromInvitePreview")) {
        this.$router.push({
          name: "InviteCrewRoutePreview",
          params: {
            inviteId: this.trip.trip_invite.id
          }
        });
      } else if (this.invitationExists) {
        this.$router.push({
          name: "SurveyRoute",
          params: {
            surveyId: this.trip.surveys[this.trip.surveys.length - 1].id
          }
        });
      } else {
        this.$router.push({
          name: "CreateInvitationForm"
        });
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);
    this.invitationExists = this.trip.trip_invitation;
    this.dataLoaded = true;
  },
  // mounted() {
  //   this.$store
  //     .dispatch("trip/get", [
  //       this.$route.params.id,
  //       {
  //         query: {
  //           include: "destination,users,survey,trip_invitation"
  //         }
  //       }
  //     ])
  //     .then((res) => {
  //       this.invitationExists = res.trip_invitation;
  //     });
  // },
  destroyed() {
    sessionStorage.removeItem("fromInvitePreview");
  }
};
</script>
